.blog-content p {
  margin-bottom: 1.5em;
  line-height: 1.8;
  font-size: 1.1rem;
  color: #2c3e50;
}

.blog-content h1,
.blog-content h2,
.blog-content h3,
.blog-content h4,
.blog-content h5,
.blog-content h6 {
  margin: 1.5em 0 0.8em;
  color: #1a73e8;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.blog-content a {
  color: #228be6;
  text-decoration: none;
  border-bottom: 1px solid rgba(34, 139, 230, 0.3);
  transition: border-color 0.2s ease;
}

.blog-content a:hover {
  border-bottom-color: #228be6;
}

.blog-content blockquote {
  margin: 2em 0;
  padding: 1em 2em;
  border-left: 4px solid #228be6;
  background-color: rgba(34, 139, 230, 0.05);
  font-style: italic;
  border-radius: 4px;
}

.blog-content ul,
.blog-content ol {
  margin: 1.5em 0;
  padding-left: 2em;
}

.blog-content li {
  margin-bottom: 0.5em;
  line-height: 1.6;
}

.blog-content img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 2em 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.blog-content code {
  background-color: #f8f9fa;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 0.9em;
  color: #e83e8c;
}

.blog-content pre {
  background-color: #f8f9fa;
  padding: 1em;
  border-radius: 8px;
  overflow-x: auto;
  margin: 1.5em 0;
}

/* Drop cap styling for the first section */
.blog-content-with-dropcap > p:first-of-type::first-letter {
  initial-letter: 2;
  -webkit-initial-letter: 2;
  color: #228be6;
  font-family: Georgia, serif;
  font-weight: bold;
  margin-right: 0.1rem;
  float: left;
  font-size: 3.5em;
  line-height: 0.8;
  padding-top: 0.1em;
}

/* No drop cap styling for content after the ad */
.blog-content-no-dropcap p::first-letter {
  initial-letter: unset;
  -webkit-initial-letter: unset;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  margin-right: 0;
  float: none;
  font-size: inherit;
  line-height: inherit;
  padding-top: 0;
}